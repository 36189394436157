/** ***************************************************************************
 * Molecules / Canvas / Eraser
 *************************************************************************** */

import AbstractTool from './abstract_tool';


class Eraser extends AbstractTool {
  constructor() {
    super(
      'eraser',
      'url("data:image/svg+xml,%3Csvg%20width%3D%2240px%22%20height%3D%2240px%22%20viewBox%3D%220%200%2040%2040%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20fill%3D%22%23342d41%22%20fill-opacity%3D%220.6%22%20cx%3D%2220%22%20cy%3D%2220%22%20r%3D%2220%22%2F%3E%3C%2Fsvg%3E") 20 20, crosshair',
    );
  }

  configureContext(context) {
    context.lineWidth = 40;
    context.lineCap = 'round';
    context.lineJoin = 'round';
    context.globalCompositeOperation = 'destination-out';
  }
}


export { Eraser as default };
