/** ***************************************************************************
 * Molecules / Canvas
 *************************************************************************** */

import Eraser from './eraser';
import Pencil from './pencil';


class CanvasComponent {
  constructor(element, resizeObserver) {
    this.element = element;
    this.element.smCanvas = this;

    this.setUpeventListeners();

    this._tools = {
      pencil: new Pencil(window.getComputedStyle(this.element, null).getPropertyValue('color')),
      eraser: new Eraser(),
    }

    this.ctx = this.element.getContext('2d');
    this.coord = { x: 0, y: 0 };
    this.origin = { x: 0, y: 0 };

    this.resizeContext();
    resizeObserver.observe(this.element);

    this.element.addEventListener('mousedown', this.mousedown);
    this.element.addEventListener('mouseup', this.mouseup);

    this.element.addEventListener('touchstart', this.touchstart);
    this.element.addEventListener('touchend', this.touchend);
  }

  destroy() {
    this.clear();
    this.deactivate();
    resizeObserver.unobserve(this.element);
    this.element.smCanvas = undefined;
    this.element.removeEventListener('mousedown', this.mousedown);
    this.element.removeEventListener('mouseup', this.mouseup);
    this.element.removeEventListener('mouseout', this.mouseout, { once: true });
    this.element.removeEventListener('touchstart', this.touchstart);
    this.element.removeEventListener('touchend', this.touchend);
  }

  // Methods

  activateTool(toolName) {
    if (toolName in this._tools) {
      const tool = this._tools[toolName];
      this.element.style.setProperty('--sm-c-canvas--Cursor', tool.cursor);
      this.tool = tool;
      document.querySelector('body').style.setProperty('touch-action', 'none');
    }
  }

  deactivate() {
    this.element.style.removeProperty('--sm-c-canvas--Cursor');
    document.querySelector('body').style.removeProperty('touch-action');
    this.tool = null;
  }

  resizeContext(blockSize, inlineSize) {
    const rect = this.element.getBoundingClientRect();
    const previousTransform = this.ctx.getTransform();

    inlineSize = inlineSize || rect.width;
    blockSize = blockSize || rect.height;

    this.element.setAttribute('width', inlineSize);
    this.element.setAttribute('height', blockSize);
    this.ctx.setTransform(previousTransform);
  }

  reposition(x, y) {
    this.coord.x = x;
    this.coord.y = y;
  }

  translate(x, y) {
    if (!matchMedia('(pointer:coarse)').matches) {
      this.ctx.translate(x - this.origin.x, y - this.origin.y);
      this.origin.x = x;
      this.origin.y = y;
    }
  }

  clear() {
    const canvas = this.element;
    this.ctx.clearRect(0, 0, canvas.width, canvas.height);
  }

  draw(x, y) {
    if (this.tool) {
      this.tool.configureContext(this.ctx);
      this.ctx.beginPath();
      this.ctx.moveTo(this.coord.x, this.coord.y);
      this.reposition(x, y);
      this.ctx.lineTo(this.coord.x, this.coord.y);
      this.ctx.stroke();
    }
  }

  start() {
    this.element.dispatchEvent(new Event('canvas:start'));
  }

  stop() {
    this.element.dispatchEvent(new Event('canvas:stop'));
  }

  // Event listeners

  setUpeventListeners() {
    this.mousedown = (event) => {
      if (event.button == 0) {
        this.start();
        this.reposition(event);
        event.target.addEventListener('mousemove', this.mousemove);
        event.target.addEventListener('mouseout', this.mouseout, { once: true });
      }
    };

    this.mouseout = (event) => {
      this.stop();
      event.target.removeEventListener('mousemove', this.mousemove);
    };

    this.mousemove = (event) => {
      this.draw(
        event.clientX - event.target.offsetLeft,
        event.clientY - event.target.offsetTop,
      );
    };

    this.mouseup = (event) => {
      this.stop();
      event.target.removeEventListener('mousemove', this.mousemove);
    };

    this.touchstart = (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();  // disable zoom
      }
      if (this.tool && event.touches.length == 1) {
        this.start();
        this.reposition(event);
        event.target.addEventListener('touchmove', this.touchmove);
      }
    };

    this.touchmove = (event) => {
      if (this.tool && event.touches.length == 1) {
        event.preventDefault();  // don't scroll while drawing
        const touch = event.touches[0];
        this.draw(touch.pageX, touch.pageY);
      }
    };

    this.touchend = (event) => {
      this.stop();
      event.target.removeEventListener('touchmove', this.touchmove);
    };
  }
}


export { CanvasComponent as default };
