/** ***************************************************************************
 * Templates / Base
 *************************************************************************** */

import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';

import CanvasComponent from '../../02-molecules/canvas/canvas';
import RemoteComponent from '../../02-molecules/remote/remote';

const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

window.addEventListener('supermatita:init', () => {
  const resizeObserver = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.contentBoxSize && entry.target.smCanvas) {
        // Firefox implements `contentBoxSize` as a single content rect, rather than an
        // array
        const contentBoxSize = Array.isArray(entry.contentBoxSize)
          ? entry.contentBoxSize[0]
          : entry.contentBoxSize;
        entry.target.smCanvas.resizeContext(
          contentBoxSize.blockSize, contentBoxSize.inlineSize
        );
      }
    });
  });
  const canvasElement = document.querySelector('body > .sm-c-canvas');
  const remoteElement = document.querySelector('body > .sm-c-page-header .sm-c-remote');

  function onstart() {
    document.querySelector('body').classList.add('sm-m-drawing');
  }

  function onstop() {
    document.querySelector('body').classList.remove('sm-m-drawing');
  }

  function initialize() {
    const canvasComponent = new CanvasComponent(canvasElement, resizeObserver);
    const remoteComponent = new RemoteComponent(remoteElement, canvasComponent);

    history.scrollRestoration = 'manual';
    window.addEventListener('beforeunload', () => window.scrollTo(0, 0));
    document.addEventListener(
      'scroll',
      (event) => canvasComponent.translate(window.scrollX, window.scrollY)
    );

    canvasElement.addEventListener('canvas:start', onstart);
    canvasElement.addEventListener('canvas:stop', onstop);
  }

  function destroy() {
    canvasElement.removeEventListener('canvas:start', onstart);
    canvasElement.removeEventListener('canvas:stop', onstop);
  }

  window.SUPERMATITA.register('templates-base', initialize, destroy);

  initialize();

});
